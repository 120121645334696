import request from '@/utils/request'


// 查询平台推送列表
export function listPush(query) {
  return request({
    url: '/video/platform-push/list',
    method: 'get',
    params: query
  })
}

// 查询平台推送分页
export function pagePush(query) {
  return request({
    url: '/video/platform-push/page',
    method: 'get',
    params: query
  })
}

// 查询平台推送详细
export function getPush(data) {
  return request({
    url: '/video/platform-push/detail',
    method: 'get',
    params: data
  })
}

// 新增平台推送
export function addPush(data) {
  return request({
    url: '/video/platform-push/add',
    method: 'post',
    data: data
  })
}

// 修改平台推送
export function updatePush(data) {
  return request({
    url: '/video/platform-push/edit',
    method: 'post',
    data: data
  })
}

// 删除平台推送
export function delPush(data) {
  return request({
    url: '/video/platform-push/delete',
    method: 'post',
    data: data
  })
}
