<template>
  <a-drawer width="80%" :label-col="4" :wrapper-col="14" :maskClosable="false" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" allow-clear/>
      </a-form-model-item>

      <a-form-model-item label="英文标题" prop="titleEn" >
        <a-input  v-model="form.titleEn" allow-clear/>
      </a-form-model-item>


      <a-form-model-item label="内容" prop="content" >
        <a-textarea :row="3" v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="英文内容" prop="contentEn" >
        <a-textarea :row="3" v-model="form.contentEn" />
      </a-form-model-item>
      <a-form-model-item label="跳转页面" prop="toPage" >
        <a-select v-model="form.toPage" placeholder="请选择">
          <a-select-option :value="1">单部剧详情</a-select-option>
          <a-select-option :value="2">rewards页面</a-select-option>
          <a-select-option :value="3">most trending</a-select-option>
          <a-select-option :value="4">剧集列表</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="目标短剧" prop="targetId" v-if="form.toPage == 1 || form.toPage == 4" >
        <a-select v-model="form.targetId" style="width: 100%" placeholder="请选择">
          <a-select-option v-for="(video,index) in videoList" :key="index" :value="video.id">
            {{ video.videoName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="推送用户" prop="toUser" >
        <a-select v-model="form.toUser" placeholder="请选择">
          <a-select-option :value="1">全站</a-select-option>
          <a-select-option :value="2">有资格的用户</a-select-option>
          <a-select-option :value="3">周/月/年订阅用户</a-select-option>
          <a-select-option :value="4">有金币消费历史用户</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="时间类型" prop="timeType" >
        <a-select v-model="form.timeType" prop="timeType">
          <a-select-option :value="1">立即发送</a-select-option>
          <a-select-option :value="2">指定时间</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="发送时间" prop="sendTime" v-if="form.timeType == 2" >
        <a-date-picker
          style="width: 100%"
          v-model="form.sendTime"
          format="YYYY-MM-DD HH:mm"
          allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPush, addPush, updatePush } from '@/api/video/push'
import Editor from '@/components/Editor'
import {listVideo} from "@/api/video/video";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      videoList: [],
      // 表单参数
      form: {
        id: null,

        content: null,

        contentEn: null,
        title: null,

        titleEn: null,

        toPage: null,

        targetId: null,

        toUser: null,

        timeType: null,

        sendTime: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        content: [
          { required: true, message: '内容不能为空' }
        ],
        title: [
          { required: true, message: '标题不能为空' }
        ],
        titleEn: [
          { required: true, message: '英文标题不能为空' }
        ],
        contentEn: [
          { required: true, message: '英文内容不能为空' }
        ],
        toPage: [
          { required: true, message: '跳转类型不能为空' }
        ],
        toUser: [
          { required: true, message: '推送类型不能为空' }
        ],
        timeType: [
          { required: true, message: '时间类型不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.queryVideoList()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    queryVideoList() {
      const param = {}
      listVideo(param).then(response => {
        this.videoList = response.data
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        content: null,
        contentEn: null,
        toPage: null,
        toUser: null,
        title: null,
        titleEn: null,
        timeType: null,
        sendTime: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPush({ 'id': id }).then(response => {
        this.form = response.data
        debugger
        console.log(this.form)
        this.open = true
        this.formTitle = '修改'
      })
    },
    formatDateTime(dateTimeStr) {
      // 假设dateTimeStr是形如"YYYY-MM-DD HH:mm:ss"的字符串
      debugger
      if (!dateTimeStr) {
        dateTimeStr = new Date()
      }
      const dateTime = new Date(dateTimeStr)

      // 检查日期是否有效
      if (isNaN(dateTime.getTime())) {
        throw new Error('Invalid date time string')
      }

      // 获取并格式化日期和时间的各个部分
      const year = dateTime.getFullYear()
      const month = String(dateTime.getMonth() + 1).padStart(2, '0') // 月份从0开始，所以需要+1
      const day = String(dateTime.getDate()).padStart(2, '0')
      const hours = String(dateTime.getHours()).padStart(2, '0')
      const minutes = String(dateTime.getMinutes()).padStart(2, '0')
      const seconds = String(dateTime.getSeconds()).padStart(2, '0')

      // 使用模板字符串进行格式化
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.form.sendTime = this.formatDateTime(this.form.sendTime)
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePush(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPush(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
